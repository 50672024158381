<template>
  <!-- <v-container fluid>
    <v-row>
        <v-col sm-12 md-12 lg-12>
            <p v-html="instruction"></p>
        </v-col>
    </v-row>      
    <v-row>
        <v-col class="d-flex justify-end" sm-12 md-12 lg-12>
            <v-btn color="primary" @click="startReview()">{{start}}</v-btn>
        </v-col>
    </v-row>        
  </v-container> -->

  <v-card class="mx-auto" color="#26c6da" dark max-width="400">
    <v-card-title>
      <v-icon lare left> mdi-flip-to-front </v-icon>
      <span class="text-h5 font-weight-bold">Start Review</span>
    </v-card-title>

    <v-card-text class="text-h5 font-weight-light" v-html="instruction">
    </v-card-text>

    <v-card-actions>
      <v-list-item class="grow">
        <v-row align="center" justify="end">
          <v-btn class="mx-2" dark rounded color="success" @click="startReview()">
            Start
            <v-icon dark> mdi-play </v-icon>
          </v-btn>
        </v-row>
      </v-list-item>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ReviewDataService } from "../../services/DataServiceiReview";

const reviewDataService = new ReviewDataService();

export default {
  data() {
    return {
      instruction: "",
      start: "start",
      reviewCode: "CA001",
    };
  },

  mounted() {
    this.getSearchData();
  },

  methods: {
    getSearchData() {
      this.$route.params.data.country = "Malaysia";
      reviewDataService.getSearchData(this.$route.params.data).then((res) => {
        this.arrays = res.overall;

        this.$setLoader();
        reviewDataService
          .getReviewInfo({
            country: "Malaysia",
            segmentId: this.arrays.segmentId,
          })
          .then((res) => {
            this.instruction = res.reviewInfo.instruction;
            this.$clearLoader();
          });
      })
    },

    startReview() {
      this.$router.push({
        name: "iReviewUpload",
        params: { reviewCode: this.reviewCode, data: this.arrays, userId: this.$store.state.userProfile._id },
      });
    },
  },
};
</script>